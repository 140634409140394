import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-587dbe74"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "footer py-4 d-flex flex-lg-column",
  id: "kt_footer"
}
const _hoisted_2 = { class: "d-flex w-95" }
const _hoisted_3 = { class: "text-muted fw-bold me-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex flex-column flex-md-row align-items-center justify-content-between", {
        'container-fluid': _ctx.footerWidthFluid,
        'container-xxl': !_ctx.footerWidthFluid,
      }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(new Date().getFullYear()) + "©", 1),
        _createVNode(_component_router_link, { to: "/privacy-policy" }, {
          default: _withCtx(() => [
            _createTextVNode("Privacy Policy")
          ]),
          _: 1
        })
      ])
    ], 2)
  ]))
}